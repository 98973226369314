<template>
  <div>
    <b-card>
      <ValidationObserver ref="systemWallet" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
        <b-row>
          <b-col md="6">
            <input-form v-model="systemWallet.systemWallet.amount" :name="$t('global.amount')" :label="$t('global.amount')" :disabled="disableStatus" />
          </b-col>
          <b-col md="6">
            <input-form v-model="systemWallet.systemWallet.name" :name="$t('table.name')" :label="$t('table.name')" :disabled="disableStatus" />
          </b-col>
        </b-row>
      </b-form>
      </ValidationObserver>
    </b-card>
  </div>
</template>
<script>
import { core } from '../../../../config/pluginInit'
import systemWalletServices from '../services/systemWallets'
import SystemWallet from '../models/SystemWallet'
import moduleItemMixin from '../../../../Mixins/moduleItemMixin'

export default {
  mixins: [moduleItemMixin],
  data () {
    return {
      systemWallet: new SystemWallet()
    }
  },
  mounted () {
    core.index()
  },
  created () {
    if (this.id) {
      systemWalletServices.findOne(this.id).then(response => {
        this.systemWallet.fillData(response.data.data)
        this.loading = false
      })
    } else {
      this.loading = false
    }
  }
}
</script>
