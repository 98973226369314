export default class SystemWallet {
  constructor () {
    this.setInitialValue()
  }

  setInitialValue () {
    this.systemWallet = {
      amount: '',
      name: ''
    }
  }

  fillData (data) {
    if (data) {
      this.systemWallet.amount = data.amount ? data.amount : ''
      this.systemWallet.name = data.name ? data.name : ''
    } else {
      this.setInitialValue()
    }
  }
}
